* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

html, body {
  height: 100vh;
  margin: 0;
  overflow-x:hidden;
}

.header {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  font-family: 'Amazon Ember';
  color: #161E2D;
}

.header-enter-password {
  font-family: 'Amazon Ember';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  color: #161E2D;
}

.header-primary {
  color: #379AEC;
}

.description {
  font-family: 'Amazon Ember';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #394451;
}

.password-rules-text {
  font-family: 'Amazon Ember';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #56616C;
}

.password-rules {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-bottom: 5px;
}

.label-text {
  font-family: 'Amazon Ember';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #394451;
}

.input-with-error-banner {
  display: flex;
  flex-direction: column;
}

.footer-text {
  font-family: 'Amazon Ember';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #56616C;
}

.footer-container {
  align-items: center;
  justify-content: center;
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 400px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 64px;
  border: 2px solid #8B96A3;
  text-align: center;
  font-size: 32px;
}

ul {
  list-style-type: none;
  padding-left: 0px;
  color: #56616C;
  font-size: 16px;
}

li {
  position: relative;
  padding-left: 0px;
  margin-bottom: 10px
}

.fa-icon {
  color: #E8EAEE;
}

.store-logo {
  height: 70px;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.faq-text-h3 {
  color: #0079AD;
}

.faq-text {
  font-weight: lighter;
}

.app-downloads-container {
  border: 1px solid #D1D5DA;
}

.app-downloads-title {
  font-family: 'Amazon Ember';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #232F3E;
}

.app-downloads-body {
  font-family: 'Amazon Ember';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #232F3E;
}

.app-downloads-login {
  font-family: 'Amazon Ember';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #56616C;
}

.qr-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.qr-img{
  background-image: url("@amzn/passport-v1ui/dist/atozapp_qrcode.png");
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.qr-text{
  display:flex;
  align-items:center;
  color: #394451;
  font-size: 16px;
  padding-left: 20px;
}

.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  box-sizing: border-box;
  object-fit: contain;
  overflow: hidden;
}

.row-flex-container {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.flex-box-left-image {
  background-image: url("@amzn/passport-v1ui/dist/stock-1-desktop.jpg");
}

.flex-box-left-badge-background {
  background-image: url("@amzn/passport-v1ui/dist/blue_badge_background.svg");
}

.flex-box-left-lock-background {
  background-image: url("@amzn/passport-v1ui/dist/blue_lock_background.svg");
}

.flex-box-left-confirmation-background {
  background-image: url("@amzn/passport-v1ui/dist/blue_confirmation_background.svg");
}
.flex-box-left-image, .flex-box-left-badge-background, .flex-box-left-lock-background, .flex-box-left-confirmation-background {
  background-size: cover;
  background-repeat: no-repeat;
  width: calc(5*(100% - 512px)/12 + 208px);
  height: 100%;
}

.flex-box-right-image {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.flex-box-right {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.content-wrap {
  display: flex;
  flex: 1;
  padding: 20px;
  align-self: center;
  flex-wrap: wrap;
}

.prehires-hero-content {
  display: flex;
  align-self: center;
  justify-content: center;
  height: auto;
  max-width: 515px;
}

@media (min-width: 1281px) and (min-height: 1261px)  {
  .flex-box-left-image { max-width: 595px }
  .flex-box-left-badge-background { max-width: 595px; background-image: url("@amzn/passport-v1ui/dist/blue_badge_background_large.svg")}
  .flex-box-left-lock-background { max-width: 595px; background-image: url("@amzn/passport-v1ui/dist/blue_lock_background_large.svg")}
  .flex-box-left-confirmation-background { max-width: 595px; background-image: url("@amzn/passport-v1ui/dist/blue_confirmation_background_large.svg")}
}

@media (min-width: 1281px) and (max-height: 1260px)  {
  .flex-box-left-image, .flex-box-left-badge-background, .flex-box-left-lock-background, .flex-box-left-confirmation-background { max-width: 595px }
}

@media (min-width: 971px) and (max-width: 1280px) {
  .row-flex-container{ overflow: auto;}
  .flex-box-right { overflow-y: visible }
  .flex-box-right-image { overflow-y: visible }
  .flex-box-left-image, .flex-box-left-badge-background, .flex-box-left-lock-background, .flex-box-left-confirmation-background { min-height: 100%; max-width: calc(5*(100vw - 312px)/12 + 120px);}
}

@media (max-width: 970px) {
  .row-flex-container{ flex:1; overflow: auto;}
  .flex-box-left-image { width: 100%; max-height: 30%; background-image: url("@amzn/passport-v1ui/dist/Stock-1-Tablet-Mobile.jpg")}
  .flex-box-right-image { height: 70%; overflow-y: visible;}
  .flex-box-left-badge-background, .flex-box-left-lock-background, .flex-box-left-confirmation-background { width: 100%; height: 0%; }
  .flex-box-right { height: 100%; overflow-y: visible; }
  .prehires-hero-content { justify-content: center; align-self:flex-start;}
}
